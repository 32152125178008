import { Injectable } from '@angular/core';
import Backendless from 'backendless';
import { User, UserType, PassengerProfiles } from '../../models/remote';
import Utilities from '../../helpers/utilities';

const PassengerProfileStore = Backendless.Data.of('PassengerProfiles');
const UserStore = Backendless.Data.of('Users')

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  private _userToken: string;
  private _currentUser: User;

  constructor() { }

  private processUserResponse(user: User): Promise<User | any> {
    if(UserType.Passenger !== user.type){
        this.logout();
        throw {message : 'Esta cuenta no está asociada a un usuario'};
    }
    else {
        this._userToken = user['user-token'];

        delete user['user-token'];
        this._currentUser = user;
        return Promise.resolve(user);
    }
}

public login(identity: string, password: string, stayLoggedIn: boolean = true): Promise<User | any> {
    return Backendless.UserService.login(identity, password, stayLoggedIn).then((user: User) => {
        return this.processUserResponse(user);
    }).catch(error => {
        throw Utilities.getTranslatedBackendlessError(error);
    });
}

public changePassword(currentPassword: string, newPassword: string): Promise<any> {
    return Backendless.UserService.getCurrentUser().then((user: User) => {
        if (!user) {
            setTimeout(() => {
            window.location.reload();
            }, 3000);
            throw {message: 'Usuario no identificado. cierra la app e inicia sesión de nuevo.'};
        }

        // Also a persistent login because previous one is dismissed
        return Backendless.UserService.logout().then(() => {

            return this.login(user.email, currentPassword);
        }).then((_user: Backendless.User) => {
            _user.password = newPassword;
            return Backendless.UserService.update(_user);
        }).then(() => {
            return Backendless.UserService.logout();
        }).then(() => {

            return this.login(user.email, newPassword);
        });
    });
}

public logout(): Promise<void> {
    // The invoker is responsible for navigating the user back to Public pages
    const logoutPromises = [Backendless.UserService.logout()];

    return Promise.all(logoutPromises).then(() => {
        this._userToken = null;
        this._currentUser = null;
    });
}

public register(identity: string, password: string, name: string, mobilePhoneNumber: string): Promise<boolean> {
    let user: User;
    return Backendless.UserService.register({email: identity, password, name, mobilePhoneNumber}).then((persistedUser: any) => {
        user = persistedUser;
        user.isUserEnabled = true;
        user.type = UserType.Passenger;
        return UserStore.save(user);        
    })
    .then ((response) => {
        const obj = {};
        return PassengerProfileStore.save(obj);
    })
    .then((persistedProfile: PassengerProfiles) => {
        return UserStore.setRelation(user, 'passengerProfile',[persistedProfile.objectId]);
    })
    .then(() => {
        // The registration requires email confirmation
        return user.userStatus === 'EMAIL_CONFIRMATION_PENDING';
    });
}

public resetPassword(identity: string): Promise<any> {
    return Backendless.UserService.restorePassword(identity);
}

public resendConfirmationEmail(email: string): Promise<any> {
    return (Backendless as any).Users.resendEmailConfirmation(email);
}

public isAuthenticated(): Promise<boolean> {
    // Verify Backendless session token
    return Backendless.UserService.isValidLogin().then(tokenIsValid => {
        console.log('[ TD:AuthService ] Login token validity:', tokenIsValid);
        // If token is valid, make sure it's also valid for Facebook (if applies).
        if (!tokenIsValid) {
            return this.logout().then(() => false);
        }
        return tokenIsValid;
    }).catch(error => {
        console.warn('[ TD:AuthService ] Could not validate token ', error);
        return this.logout().then(() => false);
    });
}

public getCurrentUser(): Promise<User | Backendless.User | any> {
    return this._currentUser
        ? Promise.resolve(this._currentUser)
        : Backendless.UserService.getCurrentUser().then((user: User) => {
            this._currentUser = user;
            return user;
        });
}
}
