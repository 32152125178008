/**
 *  This file contains models of the entities stored in Backendless tables.
 **/
import Backendless from 'backendless';
import { Coordinate } from './local';

export interface BackendlessObject {
    objectId?: string;
    ownerId?: string;
    created?: Date | any;
    updated?: Date | any;
}

export interface User extends BackendlessObject, Backendless.User {
    name: string
    email: string
    type: UserType
    location: Coordinate
    passengerProfile: PassengerProfiles
    driverProfile: DriverProfiles
    mobilePhoneNumber: string
    isUserEnabled: boolean
    lastLogin: Date
    socialAccount: AccountType
    userStatus: AccountStatus
}

export interface PassengerProfiles extends BackendlessObject {
    facebookCreated: Date | any
    rating: number
}

export interface DriverProfiles extends BackendlessObject {
    picture: string
}

export interface Extra extends BackendlessObject {
    name: string
    cost: number
}

export interface TripRequest extends BackendlessObject {
    driverObjectId: string;
    tripObjectId: string;
}

export interface Trip extends BackendlessObject {
    assigned: Date;
    pickupLocation: Backendless.Data.Point;
    cancelledByDriver: Date;
    cancelledByPassenger: Date;
    cancelledReason: string;
    destinationAddress: string;
    destinationLocation: Backendless.Data.Point;
    passenger: User;
    driver: User;
    ended: Date;
    extraAddedByDriver: boolean;
    extras: Extra[];
    finalSuggestedPrice: number;
    ignorePunishment: boolean;
    initialSuggestedPrice: number;
    isExpress: boolean;
    pickedPassenger: Date;
    pickupAddress: string;
    ratingByDriver: number;
    ratingByPassenger: number;
    route: Backendless.Data.Point[];
    status: number;
    waitedPassenger: Date;
}

export interface CancellationReasons extends BackendlessObject {
    name: string;
}

export interface CerberusData extends BackendlessObject {
    token: string;
}

export enum UserType {Admin = 'ADMIN', Passenger = 'PASSENGER', Driver = 'DRIVER'}

export enum AccountType {Backendless = 'BACKENDLESS', Facebook = 'FACEBOOK'}

export enum AccountStatus {EmailConfirmationPending = 'EMAIL_CONFIRMATION_PENDING', Enabled = 'ENABLED', Disabled = 'DISABLED'}